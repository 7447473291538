var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "custom-search" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.query,
          expression: "query",
        },
      ],
      attrs: { type: "text", placeholder: _vm.placeholder },
      domProps: { value: _vm.query },
      on: {
        keyup: [
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.search(_vm.defaultEngine)
          },
          function ($event) {
            $event.stopPropagation()
          },
        ],
        keydown: function ($event) {
          $event.stopPropagation()
        },
        input: function ($event) {
          if ($event.target.composing) return
          _vm.query = $event.target.value
        },
      },
    }),
    _c(
      "div",
      { staticClass: "buttons" },
      _vm._l(_vm.engines, function (engine, key) {
        return _c(
          "button",
          {
            key: key,
            on: {
              click: function ($event) {
                return _vm.search(engine)
              },
            },
          },
          [_vm._v(" " + _vm._s(engine.title) + " ")]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }